<template>
    <modal ref="modalHacerPregunta" titulo="Preguntar en el Foro" tamano="modal-lg" adicional="Enviar" :cargando="loading" :desactivar-guardar="false" :no-aceptar="true" @adicional="guardar">
        <ValidationObserver ref="validacion">
            <div class="row mx-0">
                <div class="col">
                    <ValidationProvider v-slot="{errors}" rules="required|max:120" name="titulo">
                        <label class="pl-3 text-gris f-15 my-3"> Ingrese el título de su pregunta </label>
                        <el-input v-model="model.titulo" class="w-100 input-name mb-3" placeholder="¿Ejemplo?" maxlength="121" />
                        <span class="text-danger w-100 f-12 d-block"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{errors}" rules="required|max:1200" name="texto">
                        <label class="pl-3 text-gris f-15 my-3"> Describa su duda para que se pueda escribir respuestas más acertadas </label>
                        <el-input v-model="model.texto" placeholder="Texto.." type="textarea" :rows="5" maxlength="1201" class="w-100 area-radius mb-3" />
                        <span class="text-danger w-100 f-12 d-block"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Foros from '~/services/foros/foros'

export default {
    data(){
        return {
            id_curso: parseInt(this.$route.params.id_curso),
            loading: false,
            model:{
                id: null,
                titulo: null,
                texto: null,
                id_curso: null
            }
        }
    },
    methods: {
        toggle(){
            this.limpiarForm()
            this.model.id_curso = this.id_curso
            this.$refs.modalHacerPregunta.toggle();
        },
        limpiarForm(){
            this.model = {
                id: null,
                titulo: null,
                texto: null,
                id_curso: null
            }
        },
        async guardar(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                this.loading = true
                const {data} = await Foros.crearForo(this.model)
                this.loading = false
                this.$emit('actualizar')
                this.notificacion('Mensaje', 'Pregunta creada', 'success')
                this.$refs.modalHacerPregunta.toggle();

            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>